import Swiper from 'swiper';
const sliderParentClass = '.js-investmenst-slider-parent';
const sliderClass = '.js-investmenst-slider';
const sliderPrevClass = '.js-slider-nav-prev';
const sliderNextClass = '.js-slider-nav-next';
const contentSlider = sliderElm => {
    const sliderEl = sliderElm.querySelector(sliderClass);
    const sliderPrev = sliderElm.querySelector(sliderPrevClass);
    const sliderNext = sliderElm.querySelector(sliderNextClass);
    const sliderIdentifier = sliderEl.id;
    const swiper = new Swiper(`#${sliderIdentifier}`, {
        slidesPerView: 1.2,
        loop: false,
        spaceBetween: 32,
        breakpoints: {
            768: {
                slidesPerView: 2,
            },
            1220: {
                slidesPerView: 3,
            },
        },
    });
    sliderPrev.addEventListener('click', () => {
        let change = swiper.slidePrev();
    });
    sliderNext.addEventListener('click', () => {
        let change = swiper.slideNext();
    });
    swiper.on('slideChangeTransitionEnd', function (obj) {
        if (obj.isBeginning) {
            sliderPrev.classList.add('is-end');
        } else {
            sliderPrev.classList.remove('is-end');
        }
        if (obj.isEnd) {
            sliderNext.classList.add('is-end');
        } else {
            sliderNext.classList.remove('is-end');
        }
    });
    resize(swiper);

    window.addEventListener(
        'resize',
        function (event) {
            resize(swiper);
        },
        true
    );

    function resize(slider) {
        if (slider) {
            const { slides } = slider;
            // console.log(slides.length);
            // console.log(window.innerWidth);

            if (window.innerWidth > 1220) {
                if (slides.length <= 3) {
                    sliderPrev.classList.add('is-hidden');
                    sliderNext.classList.add('is-hidden');
                } else {
                    sliderPrev.classList.remove('is-hidden');
                    sliderNext.classList.remove('is-hidden');
                }
            } else if (window.innerWidth > 768) {
                if (slides.length <= 2) {
                    sliderPrev.classList.add('is-hidden');
                    sliderNext.classList.add('is-hidden');
                } else {
                    sliderPrev.classList.remove('is-hidden');
                    sliderNext.classList.remove('is-hidden');
                }
            } else {
                if (slides.length <= 1) {
                    sliderPrev.classList.add('is-hidden');
                    sliderNext.classList.add('is-hidden');
                } else {
                    sliderPrev.classList.remove('is-hidden');
                    sliderNext.classList.remove('is-hidden');
                }
            }
        }
    }

    return {
        swiper,
    };
};

const allSliders = document.querySelectorAll(sliderParentClass);
if (allSliders) {
    allSliders.forEach(slider => contentSlider(slider));
}
